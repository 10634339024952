  <template>
  <div>
    <div class="row">
      <div class="mb-3 col">
        <label for="exampleFormControlInput1" class="form-label">via</label>
        <input type="text" class="form-control" v-model="item.via" />
      </div>
      <div class="mb-3 col">
        <label for="exampleFormControlInput1" class="form-label">Ani Type</label>
        <a-select type="text" class="form-control" v-model:value="item.ani_type">
          <a-select-option v-for="item in types" :key="item.id" :value="item.value">{{
            item.name
          }}</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col">
        <label for="exampleFormControlInput1" class="form-label">Attestation</label>
        <input type="text" class="form-control" v-model="item.attestation" />
      </div>
      <div class="mb-3 col">
        <label for="exampleFormControlInput1" class="form-label" v-if="sbc">SBC</label>
        <a-select type="text" class="form-control" v-model:value="item.sbc_id">
          <a-select-option v-for="item in sbc" :key="item.id" :value="item.id">{{
            item.name
          }}</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col">
        <label for="exampleFormControlInput1" class="form-label">Include lrn</label>
        <div>
          <a-radio-group v-model:value="item.include_lrn" :default-value="item.include_lrn">
            <a-radio :value="true">Yes</a-radio>
            <a-radio :value="false">No</a-radio>
          </a-radio-group>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" @click="create()">Submit</button>
    </div>
  </div>
</template>


<script>
import axios from '@/axios'
import { notification } from 'ant-design-vue'
export default {
  data() {
    return {
      item: {
        via: '',
        ani_type: 'ALL_NUMBERS',
        attestation: '',
        include_lrn: false,
        sbc_id: null,
      },
      sbc: null,
      types: [{ value: 'ALL_NUMBERS', name: 'All numbers' }],
    }
  },
  mounted() {
    this.loadSBC()
  },
  methods: {
    loadSBC() {
      axios
        .get('/authentication/sbc')
        .then((res) => {
          this.sbc = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    create() {
      axios.post('/authentication/policy', this.item).then(async(res) => {
         await this.$store.dispatch('created', `Policies`)
          this.$router.push('/authentication/policies')
      }).catch(err => {
          this.$store.dispatch('error', err)
      })
    },
    update() {
      axios
        .put(`/authentication/sbc/${this.id}`, this.item)
        .then((res) => {
          notification.success({
            message: 'Updated',
            //  description: 'You have successfully logged in!',
          })
          this.$router.push('/verif-whitelist')

          console.log(res)
        })
        .catch((err) => {
          notification.error({
            message: err.response.error,
            description: [...err.reponse.message],
          })
          console.log(err)
        })
    },
  },
}
</script>
